// Generated by Framer (b2780b5)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-ULO5a"

const variantClassNames = {L8JCNJbD_: "framer-v-pyyznz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "L8JCNJbD_", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-pyyznz", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"L8JCNJbD_"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-evbksa"} data-framer-name={"Ic-12x32-up"} fill={"black"} intrinsicHeight={32} intrinsicWidth={14} layoutDependency={layoutDependency} layoutId={"z4XBC4S1H"} svg={"<svg width=\"14\" height=\"32\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M1 22V10h12m0 12L1 10\" stroke=\"#DDDCDB\" stroke-width=\"1.5\"/></svg>"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ULO5a.framer-1pnpgqc, .framer-ULO5a .framer-1pnpgqc { display: block; }", ".framer-ULO5a.framer-pyyznz { height: 32px; overflow: hidden; position: relative; width: 14px; }", ".framer-ULO5a .framer-evbksa { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 14
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerFEUrEx03F: React.ComponentType<Props> = withCSS(Component, css, "framer-ULO5a") as typeof Component;
export default FramerFEUrEx03F;

FramerFEUrEx03F.displayName = "Ic-12x32-up";

FramerFEUrEx03F.defaultProps = {height: 32, width: 14};

addFonts(FramerFEUrEx03F, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})